import React from "react";
import "./footer.scss";
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import Icons from "../../icons/Icons";
import ArrowOutwardRoundedIcon from '@mui/icons-material/ArrowOutwardRounded';
import QuizRoundedIcon from '@mui/icons-material/QuizRounded';
import TipsAndUpdatesRoundedIcon from '@mui/icons-material/TipsAndUpdatesRounded';

const Footer = () => {
    return (
        <div className="footer">
            <div className="content">
                <div className="top">
                    <div className="left">
                        <span className="title">Elevate Your Business with Yomicall</span>
                        <span className="subtitle">Empowering Your Success with Cutting-Edge SaaS Solutions Built for Scalability, Efficiency, and Growth.</span>
                        <span className="btn">Join the waitlist</span>
                    </div>

                    <div className="right">
                        <div className="toplink">
                            <ArrowOutwardRoundedIcon className="arrow"/>
                            <div className="op">
                                <ArrowOutwardRoundedIcon className="link_icon"/>
                                <span className="link">asd</span>
                            </div>
                            <span className="description">asdl</span>
                        </div>
                        <div className="botlink">
                            <ArrowOutwardRoundedIcon className="arrow"/>
                            <div className="op">
                                <QuizRoundedIcon className="link_icon"/>
                                <span className="link">FAQ</span>
                            </div>
                            <span className="description">Our Frequently Asked Questions</span>
                        </div>
                    </div>
                </div>

                <div className="mid">
                </div>
            </div>
            <div className="line">
                <div className="center">
                    <div className="row">
                        <CircleRoundedIcon className="icon"/>
                        <span className="status">200 OK</span>
                    </div>
                </div>
            </div>
            <div className="continue">
                <div className="bottom">
                    <div className="left">
                        <img src={Icons.logo_light} alt="logo" className="logo" />
                    </div>
                    <div className="column">
                        <span className="imp">Studio</span>
                        <span className="item">dsjnkdlsk</span>
                        <span className="item">7lkdsn</span>
                        <span className="item">7knjdsf</span>
                    </div>
                    <div className="column">
                        <span className="imp">Resource</span>
                    </div>
                    <div className="column">
                        <span className="imp">Community</span>
                    </div>
                    <div className="column last">
                        <span className="imp">Company</span>
                    </div>
                    <div className="we">
                        <span className="binfo">
                            <a href="https://www.linkedin.com/company/yomicall/" target="_blank" rel="noopener noreferrer">Visit our LinkedIn page</a>
                        </span>

                        <span className="binfo">ceo@yomicall.com</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;