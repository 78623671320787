import React, { useState } from "react";
import "./header.scss";
import Icons from "../../icons/Icons";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <div className="header">
      <img src={Icons.logo_light} alt="icon" className="icon" />

      <div className="mid">
        <span className="item">Home</span>
        <span className="item">Solutions</span>
        <span className="item">Docs</span>
        <span className="item">Pricing</span>
      </div>

      <div className="buttons">
        <span className="btn">Join the waitlist</span>
      </div>

      <div className="menu-icon" onClick={() => setMenuOpen(!menuOpen)}>
        <span>☰</span>
      </div>

      {/* Mobile menu that appears when menu icon is clicked */}
      {menuOpen && (
        <div className="mobile-menu">
          <span className="item">Home</span>
          <span className="item">Solutions</span>
          <span className="item">Docs</span>
          <span className="item">Pricing</span>
          <span className="btn">Join the waitlist</span>
        </div>
      )}
    </div>
  );
};

export default Header;
